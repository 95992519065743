<template>
  <aside class="sidebar">
    <div class="logo-sec">
      <strong class="logo-sidebar">
        <img src="../assets/images/candela_logo.png" />
      </strong>
    </div>
    <perfect-scrollbar class="main-nav">
      <ul class="nav-list dashboard">
        <li class="nav-item  d-none">
          <RouterLink to="/dashboard" class="nav-link" @click="activeCollapse = null">
            <strong class="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.5315 11.5856L20.75 10.9604V21.2499H22C22.4142 21.2499 22.75 21.5857 22.75 21.9999C22.75 22.4141 22.4142 22.7499 22 22.7499H2.00003C1.58581 22.7499 1.25003 22.4141 1.25003 21.9999C1.25003 21.5857 1.58581 21.2499 2.00003 21.2499H3.25003V10.9604L2.46855 11.5856C2.1451 11.8443 1.67313 11.7919 1.41438 11.4684C1.15562 11.145 1.20806 10.673 1.53151 10.4143L9.65742 3.91354C11.027 2.81788 12.9731 2.81788 14.3426 3.91354L22.4685 10.4143C22.792 10.673 22.8444 11.145 22.5857 11.4684C22.3269 11.7919 21.855 11.8443 21.5315 11.5856ZM12 6.74992C10.4812 6.74992 9.25003 7.98114 9.25003 9.49992C9.25003 11.0187 10.4812 12.2499 12 12.2499C13.5188 12.2499 14.75 11.0187 14.75 9.49992C14.75 7.98114 13.5188 6.74992 12 6.74992ZM13.7459 13.3115C13.2871 13.2499 12.7143 13.2499 12.0494 13.2499H11.9507C11.2858 13.2499 10.7129 13.2499 10.2542 13.3115C9.76255 13.3776 9.29128 13.5267 8.90904 13.9089C8.52679 14.2912 8.37773 14.7624 8.31163 15.2541C8.24996 15.7128 8.24999 16.2857 8.25003 16.9505L8.25003 21.2499H9.75003H14.25H15.75L15.75 16.9506L15.75 16.827C15.7498 16.2145 15.7462 15.6841 15.6884 15.2541C15.6223 14.7624 15.4733 14.2912 15.091 13.9089C14.7088 13.5267 14.2375 13.3776 13.7459 13.3115Z"
                  fill="#1C274C" />
                <g opacity="0.5">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.75 9.5C10.75 8.80964 11.3096 8.25 12 8.25C12.6904 8.25 13.25 8.80964 13.25 9.5C13.25 10.1904 12.6904 10.75 12 10.75C11.3096 10.75 10.75 10.1904 10.75 9.5Z"
                    fill="#1C274C" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.75 9.5C10.75 8.80964 11.3096 8.25 12 8.25C12.6904 8.25 13.25 8.80964 13.25 9.5C13.25 10.1904 12.6904 10.75 12 10.75C11.3096 10.75 10.75 10.1904 10.75 9.5Z"
                    fill="#1C274C" />
                </g>
                <path opacity="0.5"
                  d="M12.0494 13.25C12.7142 13.25 13.2871 13.2499 13.7458 13.3116C14.2375 13.3777 14.7087 13.5268 15.091 13.909C15.4732 14.2913 15.6223 14.7625 15.6884 15.2542C15.7462 15.6842 15.7498 16.2146 15.75 16.827L15.75 21.25H8.25L8.25 16.9506C8.24997 16.2858 8.24993 15.7129 8.31161 15.2542C8.37771 14.7625 8.52677 14.2913 8.90901 13.909C9.29126 13.5268 9.76252 13.3777 10.2542 13.3116C10.7129 13.2499 11.2858 13.25 11.9506 13.25H12.0494Z"
                  fill="#1C274C" />
                <path opacity="0.5"
                  d="M16 3H18.5C18.7761 3 19 3.22386 19 3.5L19 7.63955L15.5 4.83955V3.5C15.5 3.22386 15.7239 3 16 3Z"
                  fill="#1C274C" />
              </svg>
            </strong>
            <span class="text">Dashboards</span>
          </RouterLink>
        </li>

        <li class="nav-item branches">
          <RouterLink to="/home" class="nav-link" @click="activeCollapse = null">
            <strong class="icon"> </strong>
            <span class="text">Dashboard</span>
          </RouterLink>
        </li>

        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'cpr', active: activeCollapse === 'cpr' }"
            @click="activeCollapse = activeCollapse === 'cpr' ? null : 'cpr'" role="button" aria-expanded="false"
            aria-controls="collapseCpr">
            <strong class="icon">
            </strong>
            <span class="text">Configuration</span>
          </a>
          <ul id="collapseCpr" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'cpr', active: activeCollapse === 'cpr' }">
            <li class="sub-nav-item" v-if="$can('department')">
              <RouterLink to="/department" class="sub-nav-link"> Department </RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('city')">
              <RouterLink to="/city" class="sub-nav-link"> City </RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('city-area')">
              <RouterLink to="/cityArea" class="sub-nav-link"> City Area </RouterLink>
            </li>
          </ul>
        </li>

        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'items', active: activeCollapse === 'items' }"
            @click="activeCollapse = activeCollapse === 'items' ? null : 'items'" role="button" aria-expanded="false"
            aria-controls="collapseItems">
            <strong class="icon"></strong>
            <span class="text">Items</span>
          </a>
          <ul id="collapseItems" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'items', active: activeCollapse === 'items' }">

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/itemDefinition" class="sub-nav-link">Item Definition</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-code-template')">
              <RouterLink to="/itemCodeTemplate" class="sub-nav-link">Item Code Template</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-combination')">
              <RouterLink to="/itemCombination" class="sub-nav-link">Item Combinations</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-size')">
              <RouterLink to="/itemSize" class="sub-nav-link">Item Sizes</RouterLink>
            </li>
          </ul>

        </li>


        
        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'purchaseOrder', active: activeCollapse === 'purchaseOrder' }"
            @click="activeCollapse = activeCollapse === 'purchaseOrder' ? null : 'purchaseOrder'" role="button" aria-expanded="false"
            aria-controls="collapsePurchaseOrder">
            <strong class="icon"></strong>
            <span class="text">Purchase</span>
          </a>
          <ul id="collapsePurchaseOrder" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'purchaseOrder', active: activeCollapse === 'purchaseOrder' }">

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/purchaseOrder" class="sub-nav-link">Purchase Order</RouterLink>
            </li>

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/GRN" class="sub-nav-link">GRN (Goods Receipt Note)</RouterLink>
            </li>
          </ul>

        </li>

        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'sale', active: activeCollapse === 'sale' }"
            @click="activeCollapse = activeCollapse === 'sale' ? null : 'sale'" role="button" aria-expanded="false"
            aria-controls="collapsesale">
            <strong class="icon"></strong>
            <span class="text">Sale</span>
          </a>
          <ul id="collapsesale" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'sale', active: activeCollapse === 'sale' }">

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/sale" class="sub-nav-link">Sales And Return</RouterLink>
            </li>
          </ul>

        </li>

        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'account', active: activeCollapse === 'account' }"
            @click="activeCollapse = activeCollapse === 'account' ? null : 'account'" role="button" aria-expanded="false"
            aria-controls="collapseaccount">
            <strong class="icon"></strong>
            <span class="text">Accounts</span>
          </a>
          <ul id="collapseaccount" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'account', active: activeCollapse === 'account' }">

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/transaction" class="sub-nav-link">Account Transactions</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/ChartOfAccounts" class="sub-nav-link">Chart Of Account</RouterLink>
            </li>
            <!-- <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/sale" class="sub-nav-link">Customer Receipt</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/sale" class="sub-nav-link">Supplier Payment</RouterLink>
            </li> -->
          </ul>

        </li>

        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'customers', active: activeCollapse === 'customers' }"
            @click="activeCollapse = activeCollapse === 'customers' ? null : 'customers'" role="button" aria-expanded="false"
            aria-controls="collapsecustomers">
            <strong class="icon"></strong>
            <span class="text">Parties</span>
          </a>
          <ul id="collapsecustomers" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'customers', active: activeCollapse === 'customers' }">

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/customer" class="sub-nav-link">Customers</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-code-template')">
              <RouterLink to="/customerType" class="sub-nav-link">Customer Type</RouterLink>
            </li>
            <!-- <li class="sub-nav-item" v-if="$can('item-combination')">
              <RouterLink to="/itemCombination" class="sub-nav-link">Customer Opening Balance</RouterLink>
            </li> -->
            <li class="sub-nav-item" v-if="$can('item-size')">
              <RouterLink to="/supplier" class="sub-nav-link">Suppliers</RouterLink>
            </li>
            <!-- <li class="sub-nav-item" v-if="$can('item-size')">
              <RouterLink to="/itemSize" class="sub-nav-link">Supplier Opening Balance</RouterLink>
            </li> -->
          </ul>

        </li>

        <!-- <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'inventory', active: activeCollapse === 'inventory' }"
            @click="activeCollapse = activeCollapse === 'inventory' ? null : 'inventory'" role="button" aria-expanded="false"
            aria-controls="collapseinventory">
            <strong class="icon"></strong>
            <span class="text">Inventory  MGMT</span>
          </a>
          <ul id="collapseinventory" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'inventory', active: activeCollapse === 'inventory' }">

            <li class="sub-nav-item" v-if="$can('item-definition')">
              <RouterLink to="/itemDefinition" class="sub-nav-link">Stock Count</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-code-template')">
              <RouterLink to="/itemCodeTemplate" class="sub-nav-link">Inventory Adjustment</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-combination')">
              <RouterLink to="/itemCodeTemplate" class="sub-nav-link">Inventory Adjustment Reasons</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-size')">
              <RouterLink to="/itemCodeTemplate" class="sub-nav-link">Inventory Levels</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-size')">
              <RouterLink to="/itemCodeTemplate" class="sub-nav-link">Item Serial Number</RouterLink>
            </li>
          </ul>
        </li>  -->


        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'db-attributes', active: activeCollapse === 'db-attributes' }"
            @click="activeCollapse = activeCollapse === 'db-attributes' ? null : 'db-attributes'" role="button"
            aria-expanded="false" aria-controls="collapseItems">
            <strong class="icon"></strong>
            <span class="text">Department Based Attribute</span>
          </a>
          <ul id="collapseDBAttributes" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'db-attributes', active: activeCollapse === 'db-attributes' }">
            <li class="sub-nav-item" v-if="$can('view-item-group-list')">
              <RouterLink to="/itemGroup" class="sub-nav-link">Item Group</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-db-atttribute-one')">
              <RouterLink to="/dbAttribute1" class="sub-nav-link">DB Attribute 1</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-db-atttribute-two')">
              <RouterLink to="/dbAttribute2" class="sub-nav-link">DB Attribute 2</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-category')">
              <RouterLink to="/category" class="sub-nav-link">Category</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-subcategory')">
              <RouterLink to="/subcategory" class="sub-nav-link">Sub Category</RouterLink>
            </li>
          </ul>
        </li>


        <li class="nav-item payments">
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'it-attributes', active: activeCollapse === 'it-attributes' }"
            @click="activeCollapse = activeCollapse === 'it-attributes' ? null : 'it-attributes'" role="button"
            aria-expanded="false" aria-controls="collapseItems">
            <strong class="icon"></strong>
            <span class="text">Item Based Attribute</span>
          </a>
          <ul id="collapseItemAttributes" class="sub-nav collapse"
            :class="{ show: activeCollapse === 'it-attributes', active: activeCollapse === 'it-attributes' }">
            <li class="sub-nav-item" v-if="$can('view-calendar-list')">
              <RouterLink to="/calendarSeason" class="sub-nav-link">Calendar Season</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-db-atttribute-two')">
              <RouterLink to="/ibAttribute2" class="sub-nav-link">IB Attribute 2</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-gender')">
              <RouterLink to="/gender" class="sub-nav-link">Gender</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-brand')">
              <RouterLink to="/brand" class="sub-nav-link">Brand</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-atttribute-five')">
              <RouterLink to="/ibAttribute5" class="sub-nav-link">IB Attribute 5</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-atttribute-six')">
              <RouterLink to="/ibAttribute6" class="sub-nav-link">IB Attribute 6</RouterLink>
            </li>
            <li class="sub-nav-item" v-if="$can('item-atttribute-seven')">
              <RouterLink to="/ibAttribute7" class="sub-nav-link">IB Attribute 7</RouterLink>
            </li>
          </ul>

        </li>





        <li v-if="$can('companies')" class="nav-item branches">
          <RouterLink to="/company" class="nav-link" @click="activeCollapse = null">
            <strong class="icon"> </strong>
            <span class="text">Companies</span>
          </RouterLink>
        </li>

        <li class="nav-item branches" v-if="$can('packages')">
          <RouterLink to="/package" class="nav-link" @click="activeCollapse = null">
            <strong class="icon"> </strong>
            <span class="text">Packages</span>
          </RouterLink>
        </li>


        <li class="nav-item settings" >
          <a class="nav-link drop-down" data-bs-toggle="collapse"
            :class="{ collapsed: activeCollapse !== 'settings', active: activeCollapse === 'settings' }"
            @click="activeCollapse = activeCollapse === 'settings' ? null : 'settings'" role="button"
            aria-expanded="true" aria-controls="collapseSettings">
            <strong class="icon">
            </strong>
            <span class="text">
              Security
            </span>
          </a>
          <ul id="collapseSettings" class="sub-nav collapse" :class="{ show: activeCollapse === 'settings' }">
           
           <li class="sub-nav-item" v-if="$can('security-group')">
             <RouterLink to="/securityGroup" class="sub-nav-link">
               Security Group
             </RouterLink>
           </li>

           <li class="sub-nav-item" v-if="$can('security-user')">
             <RouterLink to="/users" class="sub-nav-link">
               Security Users
             </RouterLink>
           </li>

           <li class="sub-nav-item" v-if="$can('group-right')">
             <RouterLink to="/groupRight" class="sub-nav-link">
               Group Rights
             </RouterLink>
           </li>

           <li class="sub-nav-item" v-if="$can('change-password')">
             <RouterLink to="/change-password" class="sub-nav-link">
               Change Password
             </RouterLink>
           </li>

         </ul>

        </li>

      </ul>
    </perfect-scrollbar>
  </aside>
</template>

<script>
import { STORAGE } from '@/services';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
export default {
  components: {
    PerfectScrollbar
  },
  data() {
    return {
      userType: null,
      activeCollapse: null
    }
  },
  created() {
    const user = STORAGE.USER.get();
    this.userType = user?.data?.user_type?.slug ?? null;
  },

}
</script>
<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />